<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="text-center"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <component
        v-bind:is="activeComponent"
        @add-new-card="activeComponent = 'add-new-card'"
        @go-back-action="activeComponent = 'payment-details'"
        @close="$emit('close')"
      />
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import PaymentDetails from '@/components/Checkout/PaymentDetails';
  import AddNewCard from '@/components/Checkout/AddNewCard';

  export default {
    name: 'PaymentMethodsOverlay',
    props: ["visible"],
    components: {
      'payment-details': PaymentDetails,
      'add-new-card': AddNewCard
    },
    computed: {
      ...mapGetters([
        "paymentMethods",
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      activeComponent: 'payment-details'
    }),
    methods: {

    }
  };
</script>
