<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet address-bottom-sheet"
    scrollable
    inset
  >
    <v-sheet
      class="text-center"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          width="62"
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content">
        <v-list>
          <v-list-item-group v-model="selected_container">
            <v-list-item
              v-for="(item, index) in containersList"
              class="my-list-item"
              :key="'address-'+index"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon class="mr-4">
                  <v-checkbox
                    :input-value="active"
                    :off-icon="radio_btn_off"
                    :on-icon="radio_btn_on"
                    :ripple="false"
                    class="my-checkbox"
                  ></v-checkbox>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    two-line
                    class="title-12 text-left"
                  >
                    {{item.title}}
                  </v-list-item-title>

                  <v-list-item-subtitle
                    class="body-3"
                  >
                    {{item.subtitle}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div class="confirm-btn-container">
          <v-btn
            block
            depressed
            class="order-now-btn"
            :disabled="isSelectedFoodContainerEmpty"
            @click="$emit('close');"
          >
            Confirm
          </v-btn>
        </div>
      </div>
    </v-sheet>

  </v-bottom-sheet>
</template>
  
<script>
  import { mapGetters } from 'vuex';

  export default {
    name: "SelectFoodContainer",
    props: ["visible"],
    computed: {
      ...mapGetters([
        "containersList",
        "foodContainer"
      ]),
      show: {
        get() {
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        }
      },
      isSelectedFoodContainerEmpty() {
        return this.foodContainer === null || this.foodContainer === undefined;
      },
      selected_container: {
        get() {
          return this.$store.state.orders.foodContainer;
        },
        set(value) {
          this.$store.commit('setFoodContainer', value);
        }
      },
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on"
    })
  };
</script>
