<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="text-center"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content">
        <v-row class="mt-5">
          <v-col cols="12">
            <div class="title-12 text-left">
              When you want do your pick up 
            </div>
          </v-col>
        </v-row>

        <v-list id="checkout-pickup-options">
          <v-list-item-group v-model="selected_pickup_time">
            <v-list-item
              v-for="(item, index) in pickupTimeMenu"
              :key="'time-'+index"
              :value="item"
              two-line
              :class="{'my-list-item': index == 0}"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon class="mr-4">
                  <v-icon size="25">
                    {{getItemIcon(item.icon)}}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="title-12 text-left"
                  >
                    {{item.title}}
                  </v-list-item-title>
                  <v-list-item-subtitle class="item-subtitle">
                    {{item.subtitle}}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="mr-4">
                  <v-checkbox
                    :input-value="active"
                    :off-icon="radio_btn_off"
                    :on-icon="radio_btn_on"
                    :ripple="false"
                    class="my-checkbox"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-row>
          <v-col cols="12" class="pt-0">
            <v-btn
              block
              depressed
              class="title-12 confirm-btn"
              @click="$emit('close')"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: 'CheckoutPickupTimeOptions',
    props: ["visible"],
    computed: {
      ...mapGetters([
        "pickupTimeMenu"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      selected_pickup_time: {
        get() {
          return this.$store.state.orders.selectedOrderReceiveTime['pickup'];
        },
        set(value) {
          let payload = {prop: 'pickup', value: value}
          this.$store.commit('setSelectedOrderReceiveTimeProp', payload);
        }
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on",
      now_icon: "$vuetify.icons.now_icon",
      later_icon: "$vuetify.icons.later_icon",
    }),
    methods: {
      getItemIcon(value) {
        return this[value];
      }
    }
  };
</script>

<style type="text/css">
  #checkout-pickup-options .item-subtitle {
    text-align: left;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    color: #001254;
  }
</style>