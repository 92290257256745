<template>
  <v-row class="mb-5">
    <v-col cols="12" class="py-1">
      <v-btn
        outlined
        block
        color="#001254"
        class="title-13 checkout-item-btn"
        height="50"
        @click="$emit('open-food-container')"
      >
        <div>Food Container</div>
        <v-spacer></v-spacer>
        <div
          v-if="isFoodContainerEmpty"
          class="small-text not-completed-chip"
        >
          Not completed
        </div>
        <div
          v-else
          class="small-text completed-chip"
        >
          {{containersList[foodContainer].name}}
        </div>
        <div>
          <v-icon size="13" height="7">
            {{down_arrow_icon}}
          </v-icon>
        </div>
      </v-btn>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-btn
        outlined
        block
        color="#001254"
        class="title-13 checkout-item-btn"
        height="50"
        @click="$emit('open-address-overlay')"
      >
        <div>Address</div>
        <v-spacer></v-spacer>
        <div
          v-if="isSelectedAddressEmpty"
          class="small-text not-completed-chip"
        >
          Not completed
        </div>
        <div
          v-else
          class="small-text completed-chip"
        >
          {{selectedAddress.name}}
        </div>
        <div>
          <v-icon size="13" height="7">
            {{down_arrow_icon}}
          </v-icon>
        </div>
      </v-btn>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-btn
        outlined
        block
        color="#001254"
        class="title-13 checkout-item-btn"
        height="50"
        @click="$emit('open-delivery-time-overlay')"
      >
        <div>Delivery time</div>
        <v-spacer></v-spacer>
        <div
          v-if="isSelectedDeliveryTimeEmpty"
          class="small-text not-completed-chip"
        >
          Not completed
        </div>
        <div
          v-else
          class="small-text completed-chip"
        >
          <span v-if="showLaterDetails">{{selectedTime}}</span>
          <span v-else>
            {{selectedOrderReceiveTime[orderReceiveMethod].small_title}}
          </span>
        </div>
        <div>
          <v-icon size="13" height="7">
            {{down_arrow_icon}}
          </v-icon>
        </div>
      </v-btn>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-btn
        outlined
        block
        color="#001254"
        class="title-13 checkout-item-btn"
        height="50"
        @click="$emit('open-contact-details')"
      >
        <div>Contact Details</div>
        <v-spacer></v-spacer>
        <div
          v-if="isUserProfileEmpty"
          class="small-text not-completed-chip"
        >
          Not completed
        </div>
        <div
          v-else
          class="small-text completed-chip"
        >
          {{userProfile.phoneNumber}}
        </div>
        <div>
          <v-icon size="13" height="7">
            {{down_arrow_icon}}
          </v-icon>
        </div>
      </v-btn>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-btn
        outlined
        block
        color="#001254"
        class="title-13 checkout-item-btn"
        height="50"
        @click="$emit('open-payment-methods')"
      >
        <div>Payment Details</div>
        <v-spacer></v-spacer>
        <div
          v-if="isSelectedPaymentMethodEmpty"
          class="small-text not-completed-chip"
        >
          Not completed
        </div>
        <div
          v-else
          class="small-text completed-chip"
        >
          {{paymentMethods[selectedPaymentMethod].method}}
        </div>
        <div>
          <v-icon size="13" height="7">
            {{down_arrow_icon}}
          </v-icon>
        </div>
      </v-btn>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-text-field
        v-model="coupon_code"
        label="Coupon code"
        class="checkout-text-field"
        color="#001254"
        solo
        rounded
        flat
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="py-1">
      <v-text-field
        v-model="delivery_comment"
        label="Comments"
        class="checkout-text-field"
        color="#001254"
        solo
        rounded
        flat
        hide-details
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";

  export default {
    name: 'CheckoutDeliveryOptions',
    computed: {
      ...mapGetters([
        "containersList",
        "foodContainer",
        "selectedAddress",
        "selectedPaymentMethod",
        "paymentMethods",
        "selectedOrderReceiveTime",
        "orderReceiveMethod",
        "selectedLaterDate",
        "selectedDeliveryLaterTime",
        "deliveryTimeList",
        "userProfile"
      ]),
      isFoodContainerEmpty() {
        return this.foodContainer === null || this.foodContainer === undefined;
      },
      isSelectedAddressEmpty() {
        return this.selectedAddress === null || this.selectedAddress === undefined;
      },
      isSelectedDeliveryTimeEmpty() {
        return Object.keys(this.selectedLaterDate).length === 0 || this.deliveryTimeList.length === 0;
      },
      isSelectedPaymentMethodEmpty() {
        let result = false;
        if (this.selectedPaymentMethod === undefined || this.selectedPaymentMethod === null) {
          result = true;
        }
        return result;
      },
      showLaterDetails() {
        return this.selectedOrderReceiveTime[this.orderReceiveMethod].id == 2;
      },
      selectedTime() {
        let result = '';
        if (this.selectedDeliveryLaterTime > this.deliveryTimeList.length - 1) {
          this.setSelectedDeliveryLaterTime(0);
        } else {
          if (Object.keys(this.selectedLaterDate).length != 0 && this.deliveryTimeList.length != 0) {
            let date = moment(this.selectedLaterDate.date).format("ddd");
            let time = '';
            if (this.deliveryTimeList.length != 0) {
              time = this.deliveryTimeList[this.selectedDeliveryLaterTime].replace(/\s/g, '');
            }
            result = date + ' at ' + time;
          }
        }
        return result;
      },
      isUserProfileEmpty() {
        return Object.keys(this.userProfile).length === 0;
      },
      coupon_code: {
        get() {
          return this.$store.state.orders.couponCode;
        },
        set(value) {
          this.$store.commit('setCouponCode', value);
        }
      },
      delivery_comment: {
        get() {
          return this.$store.state.orders.deliveryComment;
        },
        set(value) {
          this.$store.commit('setDeliveryComment', value);
        }
      }
    },
    data: () => ({
      down_arrow_icon: "$vuetify.icons.down_arrow_icon"
    }),
    methods: {
      ...mapActions([
        "setSelectedDeliveryLaterTime"
      ])
    }
  };
</script>

