<template>
  <div class="my-bottom-sheet-content">
    <v-row class="mt-2">
      <v-col cols="12" class="text-left">
        <div class="title-12">Payment Details</div>
      </v-col>
    </v-row>

    <v-list>
      <v-list-item-group
        v-model="payment_method"
        mandatory
      >
        <v-list-item
          v-for="(item, index) in paymentMethods"
          :key="'method-'+index"
          class="my-list-item py-2"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-4">
              <v-icon>
                {{getItemIcon(item.icon)}}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="title-12 text-left"
              >
                {{item.method}} {{item.details}}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-checkbox
                :input-value="active"
                :off-icon="radio_btn_off"
                :on-icon="radio_btn_on"
                :ripple="false"
                readonly
                class="my-checkbox"
              ></v-checkbox>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>

      <!-- <v-list-item
        :ripple="false"
        class="py-2"
        @click="$emit('add-new-card')"
      >
        <v-list-item-icon class="mr-4">
          <v-icon>
            {{add_btn}}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="title-12 text-left"
          >
            Add new payment method
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action
          class="small-text"
        >
          Add
        </v-list-item-action>
      </v-list-item> -->
    </v-list>

    <v-row class="mt-6">
      <v-col cols="12">
        <v-btn
          block
          depressed
          class="title-12 confirm-btn"
          @click="$emit('close')"
        >
          Confirm
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: 'PaymentDetails',
    computed: {
      ...mapGetters([
        "paymentMethods",
      ]),
      payment_method: {
        get() {
          return this.$store.state.orders.selectedPaymentMethod;
        },
        set(value) {
          this.$store.commit('setSelectedPaymentMethod', value);
        }
      }
    },
    data: () => ({
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on",
      apple_icon: "$vuetify.icons.apple_icon",
      credit_card_icon: "$vuetify.icons.credit_card_icon",
      cash_icon: "$vuetify.icons.cash_icon",
      add_btn: "$vuetify.icons.add_btn",
      pos_icon: "$vuetify.icons.pos_icon"
    }),
    methods: {
      getItemIcon(value) {
        return this[value];
      }
    }
  };
</script>
