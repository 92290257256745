<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet address-bottom-sheet"
    scrollable
    inset
  >
    <v-sheet
      class="text-center"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          width="62"
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content full-height">
        <component
          v-bind:is="activeComponent"
          :hideChangeAddressBackBtn="true"
          @back-to-menu="$emit('close')"
          @change-address="activeComponent = 'change-address'"
          @add-new-address="openNewAddressForm"
          @open-edit-address-form="openEditAddressForm"
          @confirm-select-address="confirmSelectAddress"
          @create-address="createAddress"
          @edit-address="editAddressAction"
        ></component>
      </div>
    </v-sheet>

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-bottom-sheet>
</template>
  
<script>
  import { mapGetters, mapActions } from 'vuex';
  import ChangeAddress from '@/components/SelectAddress/ChangeAddress';
  import AddNewAddress from '@/components/SelectAddress/AddNewAddress';
  import EditAddress from '@/components/SelectAddress/EditAddress';

  export default {
    name: "ChangeAddressOverlay",
    props: ["visible"],
    components: {
      'change-address': ChangeAddress,
      'add-new-address': AddNewAddress,
      'edit-address': EditAddress
    },
    computed: {
      ...mapGetters([
        "userProfile",
        "isUserLoggedIn"
      ]),
      show: {
        get() {
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        }
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      activeComponent: 'change-address',
      showLoader: false
    }),
    methods: {
      ...mapActions([
        "setConfirmAddress",
        "setEditAddress",
        "getAddressList",
        "createNewAddress",
        "updateAddress",
        "setNewAddress"
      ]),
      confirmSelectAddress() {
        this.setConfirmAddress(true).then(() => {
          this.$emit("close");
        });
      },
      openNewAddressForm() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        this.activeComponent = 'add-new-address';
      },
      closeNewAddressForm() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = '100%';
        this.activeComponent = 'change-address';
      },
      openEditAddressForm(item) {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        
        let addressObj = JSON.parse(JSON.stringify(item));
        this.setEditAddress(addressObj).then(() => {
          this.activeComponent = 'edit-address';
        });
      },
      createAddress(addressObj) {
        this.showLoader = true;
        this.setNewAddress(addressObj);

        if (this.isUserLoggedIn) {
          let payload = {
            email: this.userProfile.email,
            requestData: [addressObj]
          }
          this.createNewAddress(payload).then(() => {
            this.getAddressList(this.userProfile.email).then(() => {
              this.showLoader = false;
              this.closeNewAddressForm();
            });
          });
        }
      },
      editAddressAction(addressObj) {
        this.showLoader = true;
        let email = this.userProfile.email;

        if (this.isUserLoggedIn) {
          let payload = {
            email: email,
            requestData: [addressObj]
          };

          this.updateAddress(payload).then(() => {
            this.getAddressList(email).then(() => {
              this.showLoader = false;
              this.activeComponent = 'change-address';
            });
          });
        }
      }
    }
  };
</script>
