<template>
  <div class="my-bottom-sheet-content">
    <v-row>
      <v-col cols="12" class="text-left py-2">
        <v-btn
          icon
          depressed
          width="20"
          @click="$emit('go-back-action')"
        >
          <v-icon size="20">{{back_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-left pt-5">
        <div class="title-12">Add new card</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-form>
          <v-text-field
            v-model="cardName"
            label="Card holder"
            background-color="#F2F2F2"
            class="my-text-field"
            color="#001254"
            solo
            flat
            rounded
            height="46"
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="cardNumber"
            label="Card number"
            background-color="#F2F2F2"
            class="my-text-field"
            color="#001254"
            solo
            flat
            rounded
            height="46"
            hide-details
          ></v-text-field>

          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="cvv"
                label="CVV"
                background-color="#F2F2F2"
                class="my-text-field"
                color="#001254"
                solo
                flat
                rounded
                height="46"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="5">
              <v-text-field
                v-model="expiryDate"
                label="Expiry date"
                background-color="#F2F2F2"
                class="my-text-field"
                color="#001254"
                solo
                flat
                rounded
                height="46"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12">
              <v-btn
                block
                depressed
                class="title-12 confirm-btn"
                @click="$emit('go-back-action')"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'AddNewCard',
    props: ["visible"],
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    data: () => ({
      back_btn: "$vuetify.icons.back_btn",
      cardName: '',
      cardNumber: '',
      expiryDate: '',
      cvv: ''
    })
  };
</script>

<style type="text/css">
  
</style>
