<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet address-bottom-sheet"
    scrollable
    inset
  >
    <v-sheet
      class="text-center full-height"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          width="62"
          @click="$emit('close')"
          v-touch="{
            down: () => $emit('close')
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content full-height">
        <SelectLaterTime
          :hideBackBtn="true"
          @back-to-menu="$emit('close')"
        ></SelectLaterTime>
        <v-row>
          <v-col cols="12">
            <v-btn
              block
              depressed
              class="title-12 confirm-btn"
              @click="$emit('close')"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import SelectLaterTime from '@/components/SelectAddress/SelectLaterTime';
  
  export default {
    name: 'ChangePickupTimeOverlay',
    components: {
      SelectLaterTime
    },
    props: ["visible"],
    computed: {
      show: {
        get() {
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$emit("close");
          }
        }
      },
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn"
    })
  };
</script>
