<template>
  <v-container id="checkout-page">

    <CheckoutTopBar
      @go-back-action="goToBasketReview"
      @show-nav-drawer="showNavDrawer = true"
    />
    
    <div class="my-page-content">
      <v-row class="mt-0">
        <v-col cols="12" class="py-0">
          <div class="title-4">Checkout</div>
        </v-col>
        <v-col cols="12" v-if="!isClosed" class="py-1">
          <div class="body-3">You are all set!</div>
        </v-col>
      </v-row>

      <v-container v-if="isClosed" class="fill-height">
        <v-row class="text-center mt-0">
          <v-col cols="10" class="pt-2" offset="1">
            <div class="title-6">
              Λυπούμαστε αλλά είμαστε κλειστά! <br>
              Το μενού ανανεώνεται καθημερινά στις 10:30.<br>
              Το Wecook δέχεται παραγγελίες
              <br> Δευτέρα ως Παρασκευή 10:30-19:45. <br>
              Ευχαριστούμε
            </div>
          </v-col>

          <v-col cols="4" offset="4" class="mt-5">
            <v-btn
              depressed
              class="explore-dishes-btn"
              @click="goToMainFeed"
            >
              Explore Dishes
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-else class="checkout-options">
        <v-container v-if="showOrderErrorMessage" class="fill-height">
          <v-row class="text-center mt-0">
            <v-col cols="10" class="pt-2" offset="1">
              <div class="title-6">
                Κάτι πήγε στραβά! <br>
                Δοκιμάστε να παραγγείλετε πιο παραδοσιακά.
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" offset="3" class="text-center">
              <v-btn icon href="https://wa.me/306972992817">
                <v-img
                  src="@/assets/whatsapp_icon.png"
                  width="50"
                  height="50"
                ></v-img>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-btn icon href="https://l1nq.link/?viber=306972992817">
                <v-img
                  src="@/assets/viber_icon.png"
                  width="50"
                  height="50"
                ></v-img>
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-menu
                top
                :offset-y="true"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-img
                      src="@/assets/phone_icon.png"
                      width="50"
                      height="50"
                    ></v-img>
                  </v-btn>
                </template>
  
                <v-list>
                  <v-list-item>
                    <v-list-item-title>2106420888</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <div v-else>
          <div v-if="unavailableDishes.length === 0" class="checkout-options">
            <div class="d-flex pb-5">
              <v-btn
                depressed
                height="40"
                class="delivery-btn"
                :class="{'active-btn': orderReceiveMethod === 'delivery'}"
                @click="selectReceiveOrderMethod('delivery')"
              >
                Delivery
              </v-btn>
              <v-btn
                depressed
                height="40"
                class="pickup-btn"
                :class="{'active-btn': orderReceiveMethod === 'pickup'}"
                @click="selectReceiveOrderMethod('pickup')"
              >
                Pick Up
              </v-btn>
            </div>

            <component
              v-bind:is="activeComponent"
              @open-food-container="showSelectFoodContainer = true"
              @open-address-overlay="showSelectAddress = true"
              @open-delivery-time-overlay="showChangeDeliveryTimeOverlay = true"
              @open-pickup-time-overlay="showChangePickupTimeOverlay = true"
              @open-contact-details="openContactDetailsModal"
              @open-payment-methods="showPaymentMethods = true"
            ></component>

            <!-- <v-row class="mt-5">
              <v-col cols="12" class="pb-2">
                <div class="title-10">Add tip</div>
              </v-col>
            </v-row> -->

            <!-- <AddTip /> -->
          </div>
          <div v-else class="fill-height d-flex">
            <v-container class="ma-auto">
              <v-row class="text-center mt-0">
                <v-col cols="10" class="pt-2" offset="1">
                  <div class="title-9">
                    Τα παρακάτω προϊόντα που έχετε επιλέξει δεν υπάρχουν πλέον.<br>
                    Επιλέξτε καθαρισμό καλαθιού ή αφαιρέστε τα από την παραγγελία. 
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="6" offset="3">
                  <v-list dense class="pa-0" id="unavailable-dishes-list">
                    <v-list-item
                      v-for="(item, index) in unavailableDishes"
                      :key="'unavailable-dish-'+index"
                      class="pa-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="title-14">- {{item.title}}</div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              
              <v-row class="mt-5">
                <v-col cols="4" offset="2">
                  <v-btn
                    depressed
                    class="explore-dishes-btn"
                    @click="clearOrder"
                  >
                    Clear Order
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    class="explore-dishes-btn"
                    @click="goToBasketReview"
                  >
                    Review Order
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-container>

      <div class="main-btn-container">
        <!-- <div v-if="showAddressLimitationMessage" class="address-limitation">
          {{addressLimitation.message}}
        </div> -->
        <v-btn
          depressed
          class="order-now-btn"
          :disabled="!isValidForm"
          @click="saveOrder"
        >
          Confirm Order
          <v-spacer></v-spacer>
          <span>{{orderTotal.toFixed(2)}} &euro;</span>
        </v-btn>
        <div class="body-3 text-center tip-message">
          Please don't forget to smile and tip our drivers ;)
        </div>
      </div>
    </div>

    <SelectFoodContainer
      :visible="showSelectFoodContainer"
      @close="showSelectFoodContainer = false"
    />

    <ChangeAddressOverlay
      :visible="showSelectAddress"
      @close="showSelectAddress = false"
    />

    <ChangeDeliveryTimeOverlay
      :visible="showChangeDeliveryTimeOverlay"
      @close="showChangeDeliveryTimeOverlay = false"
    />

    <ChangePickupTimeOverlay
      :visible="showChangePickupTimeOverlay"
      @close="showChangePickupTimeOverlay = false"
    />

    <CheckoutContactDetails
      :visible="showContactDetails"
      @close="showContactDetails = false"
      @edit-user="editUserAction"
    />

    <PaymentMethodsOverlay
      :visible="showPaymentMethods"
      @close="showPaymentMethods = false"
    />

    <CheckoutPickupTimeOptions
      :visible="showPickupTimeOptions"
      @close="showPickupTimeOptions = false"
    />

    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
    />

    <v-overlay :value="showLoader" z-index=102>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from 'moment';
  import axios from 'axios';
  import CheckoutTopBar from '@/components/Navigation/CheckoutTopBar';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import CheckoutDeliveryOptions from '@/components/Checkout/CheckoutDeliveryOptions';
  import CheckoutPickupOptions from '@/components/Checkout/CheckoutPickupOptions';
  import SelectFoodContainer from '@/components/Checkout/SelectFoodContainer';
  import ChangeAddressOverlay from '@/components/Checkout/ChangeAddressOverlay';
  import ChangeDeliveryTimeOverlay from '@/components/Checkout/ChangeDeliveryTimeOverlay';
  import ChangePickupTimeOverlay from '@/components/Checkout/ChangePickupTimeOverlay';
  import CheckoutContactDetails from '@/components/Checkout/CheckoutContactDetails';
  import PaymentMethodsOverlay from '@/components/Checkout/PaymentMethodsOverlay';
  import CheckoutPickupTimeOptions from '@/components/Checkout/CheckoutPickupTimeOptions';
  import AddTip from '@/components/Checkout/AddTip';

  export default {
    name: 'Checkout',
    components: {
      CheckoutTopBar,
      NavigationDrawer,
      SelectFoodContainer,
      ChangeAddressOverlay,
      ChangeDeliveryTimeOverlay,
      ChangePickupTimeOverlay,
      CheckoutContactDetails,
      PaymentMethodsOverlay,
      CheckoutPickupTimeOptions,
      'delivery': CheckoutDeliveryOptions,
      'pickup': CheckoutPickupOptions,
      AddTip
    },
    computed: {
      ...mapGetters([
        "cart",
        "originalDishList",
        "orderTotal",
        "orderComment",
        "deliveryComment",
        "couponCode",
        "orderReceiveMethod",
        "addressesObj",
        "selectedAddress",
        "pickupAddress",
        "userProfile",
        "editProfile",
        "paymentMethods",
        "selectedPaymentMethod",
        "selectedOrderReceiveTime",
        "tips",
        "selectedTip",
        "deliveryTimeList",
        "pickupTimeList",
        "selectedDeliveryLaterTime",
        "selectedPickupLaterTime",
        "selectedLaterDate",
        "myOrders",
        "containersList",
        "foodContainer",
        "addressLimitation",
        "isClosed"
      ]),
      isCartEmpty() {
        return this.cart.length == 0;
      },
      isFoodContainerEmpty() {
        return this.foodContainer === null || this.foodContainer === undefined;
      },
      isSelectedAddressEmpty() {
        return this.selectedAddress === null || this.selectedAddress === undefined;
      },
      isContactDetailsEmpty() {
        return Object.keys(this.userProfile).length === 0;
      },
      isSelectedPaymentMethodEmpty() {
        let result = false;
        if (this.selectedPaymentMethod === undefined || this.selectedPaymentMethod === null) {
          result = true;
        }
        return result;
      },
      isSelectedLaterDateEmpty() {
        return Object.keys(this.selectedLaterDate).length === 0;
      },
      // isOrderTotalValid() {
      //   let result = true;
      //   let totalLimit = null;
      //   if (this.addressLimitation != null) {
      //     totalLimit = this.addressLimitation.totalLimit;
      //   }

      //   if (totalLimit == null || this.orderTotal < totalLimit) {
      //     result = false;
      //   }
      //   return result;
      // },
      // showAddressLimitationMessage() {
      //   return this.orderReceiveMethod == 'delivery' && this.addressLimitation != null && !this.isOrderTotalValid;
      // },
      isValidForm() {
        let result = false;
        if (this.orderReceiveMethod == 'delivery') {
          result = !this.isCartEmpty && !this.isSelectedAddressEmpty && !this.isContactDetailsEmpty && !this.isSelectedPaymentMethodEmpty && !this.isFoodContainerEmpty && !this.isSelectedLaterDateEmpty;
        } else {
          result = !this.isCartEmpty && !this.isSelectedPaymentMethodEmpty && !this.isSelectedLaterDateEmpty && !this.isFoodContainerEmpty;
        }
        return result;
      }
    },
    data: () => ({
      showLoader: false,
      showSelectFoodContainer: false,
      showSelectAddress: false,
      showChangeDeliveryTimeOverlay: false,
      showChangePickupTimeOverlay: false,
      showNavDrawer: false,
      showContactDetails: false,
      showPaymentMethods: false,
      showPickupTimeOptions: false,
      activeComponent: 'delivery',
      unavailableDishes: [],
      showOrderErrorMessage: false
    }),
    mounted() {
      this.activeComponent = this.orderReceiveMethod;
      this.checkDishAvailability();
    },
    methods: {
      ...mapActions([
        "setOrderReceiveMethod",
        "setEditProfile",
        "editUserProfile",
        "getUserProfile",
        "createNewOrder",
        "resetOrderObjects",
        "setOrderDetails",
        "setOrderResponse",
        "getMyOrders",
        "resetSelectedLaterDate"
      ]),
      goToBasketReview() {
        this.$router.push({name: 'basket_review'});
      },
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      saveOrder() {
        this.showLoader = true;

        if (this.orderReceiveMethod == 'delivery') {
          let selectedAddressId = this.selectedAddress.id;
          this.addressesObj.content.forEach((el) => {
            el.selectedAddress = false;
            if (el.id == selectedAddressId) {
              el.selectedAddress = true;
            }
          });
        }
        let customer = {...this.userProfile, addresses: this.addressesObj};

        let items = [];
        this.cart.forEach((el) => {
          let dishItem = this.originalDishList.find(d => d.id == el.id);
          let modifierDetails = [];
          let modifierGroups = [];

          if (dishItem?.modifierGroups != undefined && dishItem.modifierGroups.length != 0) {
            dishItem.modifierGroups.forEach((modGroup) => {
              for (let list of modGroup.group.modifiers) {
                let type = modGroup.group.modifiers[0].type;
                if (type == 0 && list.values) {
                  list.values.forEach((val, index) => {
                    val.selected = false;
                    if (el.selectedModifiers.includes(index)) {
                      val.selected = true;
                      let details_1 = {
                        id: val.id,
                        title: val.content.en.title
                      };
                      modifierDetails.push(details_1);
                    }
                  });
                } else if (type == 3 && list.values) {
                  list.values.forEach((val, index) => {
                    val.selected = false;
                  });
                  list.values[el.selectedIngredient].selected = true;
                  let details_2 = {
                    id: list.values[el.selectedIngredient].id,
                    title: list.values[el.selectedIngredient].content.en.title
                  };
                  modifierDetails.push(details_2);
                }
              }
            });
            modifierGroups = dishItem.modifierGroups;
          }
          dishItem.basePrices[0].totalPrice = el.totalItemPrice;
          dishItem.basePrices[0].quantity = el.quantity;
          dishItem.basePrices[0].modifierTable = [{
            modifierDetails: modifierDetails,
            modifierDetailsWith: [],
            modifierDetailsWithout: [],
            modifierDetailsTitlesWith: [],
            modifierDetailsTitlesWithout: [],
            totalPrice: dishItem.basePrices[0].totalPrice,
            quantity: 1
          }];

          let item = {
            id: null,
            content: null,
            modifierGroups: modifierGroups,
            landingFile: null,
            isVegan: false,
            isGlutenFree: false,
            isLactoseFree: false,
            isVegeterian: false,
            isNew: false,
            onePlusOne: false,
            isFirstOrderOfferItem: false
          };

          items.push({
            id: dishItem.id,
            item: item,
            basePrices: dishItem.basePrices,
            content: null,
            type: 0,
            slang: null,
            special: false, 
            isVegan: false,
            isGlutenFree: false,
            isLactoseFree: false,
            isVegeterian: false,
            isNew: false,
            onePlusOne: false,
            isFirstOrderOfferItem: false,
            tabId: null
          });
        });

        // ReceptionType:
        // DELIVERY(0), TAKE_AWAY(1), DINE_IN(2), LOCAL(3)
        let receptionType = 0;
        let orderAddress = null;
        if (this.orderReceiveMethod == 'delivery') {
          receptionType = 0;
          orderAddress = this.selectedAddress;
        } else {
          receptionType = 1;
          orderAddress = null;
        }

        let customHour = '';
        if (this.selectedOrderReceiveTime[this.orderReceiveMethod].id == 2) {
          if (this.orderReceiveMethod == 'delivery') {
            if (this.deliveryTimeList[this.selectedDeliveryLaterTime] == undefined) {
              this.selectedDeliveryLaterTime = 0;
              this.resetSelectedLaterDate().then(() => {
                this.$router.go();
              });
            } else {
              customHour = this.deliveryTimeList[this.selectedDeliveryLaterTime];
            }
          } else {
            if (this.pickupTimeList[this.selectedPickupLaterTime] == undefined) {
              this.selectedPickupLaterTime = 0;
              this.resetSelectedLaterDate().then(() => {
                this.$router.go();
              });
            } else {
              customHour = this.pickupTimeList[this.selectedPickupLaterTime];
            }
          }
        }

        // PaymentMethodType:
        // CASH(0), CARD(1), POS(2), POINTS(3), PAYPAL(4), VIVA(5), SIMPLIFY(6)
        let paymentMethod = this.paymentMethods[this.selectedPaymentMethod].value;

        let comments = 'coupon: ' + this.couponCode + ', delivery comment: ' + this.deliveryComment + ', comment: ' + this.orderComment+ ', container: ' + this.containersList[this.foodContainer].title;

        let orderData = {
          address: orderAddress,
          items: items,
          comments: comments,
          customer: customer,
          paymentMethodType: paymentMethod,
          receptionType: receptionType,
          loyaltyPoints: null,
          invoiceInfo: null,
          customHour: customHour,
          invoice: false,
          smsPhoneNumber: this.userProfile.phoneNumber
        };

        this.createNewOrder(orderData).then((res) => {
          let basketItems = this.cart.map((el) => {
            let itemCategory = el.tags.length != 0 ? el.tags[0].name.toLowerCase() : '';
              return {
                'item_name': el.title,
                'price': el.price,
                'quantity': el.quantity,
                'item_category': itemCategory
              }
          });


          this.$gtag.event('purchase', {
            'currency': 'EUR',
            'value': this.orderTotal,
            'transaction_id': res.id,
            'items': basketItems
          });

          let contents = this.cart.map((el) => {
              return {
                id: el.id,
                item_price: el.price,
                quantity: el.quantity
              }
          });

          fbq('track', 'Purchase', {
            content_name: 'Food Purchase',
            content_type: 'product', //Leave as is
            value: this.orderTotal, //quantity * price
            currency: 'EUR', //Currency of the transaction
            contents:  contents
          });


          this.setOrderResponse(res);
          if (paymentMethod == 1) {
            console.log('open viva');
            let orderCode = res.orderCodeViva;
            let vivaUrl = `https://demo.vivapayments.com/web/checkout?ref=${orderCode}`;
          } else {
            let orderId = res.id;

            let myOrdersPayload = {
              customerId: this.userProfile.id,
              email: this.userProfile.email
            };

            this.getMyOrders(myOrdersPayload).then(() => {
              let orderObj = this.myOrders.find(el => el.id == orderId);
              if (orderObj) {
                this.setOrderDetails(orderObj).then(() => {
                  this.resetOrderObjects().then(() => {
                    this.showLoader = false;
                    this.$router.push({name: 'order_confirmed'});
                  });
                });
              } else {
                this.resetOrderObjects().then(() => {
                  this.showLoader = false;
                  this.$router.push({name: 'order_confirmed'});
                });
              }
            });
          }
        }).catch((err) => {
          this.showLoader = false;
          this.showOrderErrorMessage = true;
          console.log(err);
        });
      },
      selectReceiveOrderMethod(method) {
        this.setOrderReceiveMethod(method).then(() => {
          this.activeComponent = method;
        });
      },
      openContactDetailsModal() {
        let userObj = JSON.parse(JSON.stringify(this.userProfile));
        let data = {
          firstName: userObj.firstName,
          lastName: userObj.lastName,
          phoneNumber: userObj.phoneNumber,
          email: userObj.email
        }
        this.setEditProfile(data).then(() => {
          this.showContactDetails = true;
        })
      },
      editUserAction() {
        this.showContactDetails = false;
        this.showLoader = true;
        this.editUserProfile(this.editProfile).then(() => {
          this.getUserProfile().then(() => {
            this.showLoader = false;
          });
        });
      },
      checkDishAvailability() {
        return new Promise((resolve, reject) => {
          let url = `${process.env.VUE_APP_BASE_URL}/wecook/dish/list/${process.env.VUE_APP_ACCOUNT_ID}/chef/enabled`;
          axios.get(url, {}).then(res => {
            let response = res.data.content.map((el) => {
              return el.id;
            });
            this.cart.forEach((el) => {
              let foundItemIndex = response.findIndex((item) => item === el.id);
              if (foundItemIndex == -1) {
                this.unavailableDishes.push(el);
              }
            })
            resolve();
          }).catch(er => {
            console.log(er);
            reject();
          });
        });
      },
      clearOrder() {
        this.showLoader = true;

        this.resetOrderObjects().then(() => {
          this.showLoader = false;
          this.goToBasketReview();
        });
      }
    }
  };
</script>

<style type="text/css">
  #checkout-page {
    padding: 20px 20px 160px;
    height: 100%;
  }

  #checkout-page .my-page-content {
    height: calc(100% - 50px);
    margin-top: 36px;
  }

  #checkout-page .delivery-btn,
  #checkout-page .pickup-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #001254;
    background-color: #F2F2F2;
    border-radius: 20px;
    padding: 12px 40px;
  }

  #checkout-page .pickup-btn {
    margin-left: -30px;
    z-index: 0;
  }

  #checkout-page .active-btn {
    background-color: #001254;
    color: #ffffff;
    z-index: 1;
  }

  #checkout-page .checkout-item-btn {
    border-radius: 33.5px;
    padding: 18px 22px;
  }

  #checkout-page .checkout-text-field.v-input {
    height: 50px;
    border-radius: 33.5px;
    border: thin solid #001254;
  }

  #checkout-page .checkout-text-field.v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 22px;
  }

  #checkout-page .checkout-text-field.v-input input,
  #checkout-page .checkout-text-field.v-input .v-text-field__slot label,
  #checkout-page .checkout-text-field.v-input input::placeholder {
    color: #001254;
    font-size: 16px;
    font-weight: normal;
    line-height: 120%;
  }

  .completed-chip {
    margin-right: 20px;
    padding: 4px 10px;
    background-color: #C4F3FF;
    border-radius: 10px;
  }

  .not-completed-chip {
    margin-right: 20px;
    padding: 4px 10px;
    background-color: #FF6700;
    border-radius: 10px;
    color: #ffffff;
  }

  .tip-message {
    opacity: 0.5;
    margin-top: 8px;
  }

  .checkout-options {
    height: 480px;
    padding: 8px 0;
  }

  #checkout-page .main-btn-container {
    bottom: 8px;
  }

  @media (max-width: 600px) {
    .completed-chip,
    .not-completed-chip {
      margin-right: 10px;
    }

    #checkout-page .checkout-text-field.v-text-field--rounded > .v-input__control > .v-input__slot {
      padding: 0 12px;
    }

    #checkout-page .checkout-item-btn {
      padding: 18px 12px;
    }
  }
</style>
